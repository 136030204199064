<template>
  <div>
    <slot name="header" />
    <div class="pb-12">
      <slot :is-valid="true" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>
